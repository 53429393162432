/* Extend jQuery with functions for PUT and DELETE requests. */
jQuery.extend({
    fetch: function(url, data, type, callback, error) {
        return _ajax_request(url, data, callback, type, 'GET', error);
    },
    create: function(url, data, type, callback, error) {
        return _ajax_request(url, data, callback, type, 'POST', error);
    },
    update: function(url, data, type, callback, error) {
        return _ajax_request(url, data, callback, type, 'PUT', error);
    },
    delete: function(url, data, type, callback, error) {
        return _ajax_request(url, data, callback, type, 'DELETE', error);
    }
});

function _ajax_request(url, data, callback, type, method, error) {
    if (jQuery.isFunction(data)) {
        callback = data;
        data = {};
    }
    return jQuery.ajax({
        type: method,
        url: url,
        data: data,
        dataType: type,
        contentType: false,
        processData: false,
        success: callback,
        error: error
    });
}


if(jQuery('.datepicker').length){
    let todayDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    jQuery('.datepicker').each(function () {
        jQuery(this).datepicker({
            format: 'dd-mm-yyyy',
            minDate: todayDate
        });
    })
}

window.home_url = $('.header-logo-wrap').find('a').attr('href');
