(function ($) {
    let body = $("body");
    let shippingBlock = $(".shipping_block");
    let volumeBlock = $(".volume_block");
    let priceBlock = $(".price_block");
    let shipping_type = 1;
    let origin = {};
    let destination = {};
    let width = 0;
    let height = 0;
    let length = 0;

    $("#originSelect").select2({
        placeholder: "Select Origin",
        ajax: {
            url: home_url + "/api/country/1/locations",
            data: function (params) {
                return { search: params.term };
            },
        },
    });

    $("#originSelect").on("select2:selecting", function (e) {
        origin = e.params.args.data;
        $(".origin_block")
            .find("h4")
            .text(origin.text + ", USA");
    });

    $("#destinationSelect").select2({
        placeholder: "Select Destination",
        ajax: {
            url: home_url + "/api/country/2/locations",
            data: function (params) {
                return { search: params.term };
            },
        },
    });

    $("#destinationSelect").on("select2:selecting", function (e) {
        destination = e.params.args.data;
        $(".destination_block")
            .find("h4")
            .text(destination.text + ", Sri Lanka");
    });

    $(document).on("change", "input[name=shipping_type]", function (e) {
        shipping_type = $(this).val();
        $(".radio-wrapper").removeClass("active");
        let parentWrapper = $(this).parents(".radio-wrapper");
        parentWrapper.addClass("active");
    });

    const formatNumber = (n) =>  {
        var parts = n.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return (
            numberPart.replace(thousands, ",") +
            (decimalPart ? "." + decimalPart : "")
        );
    }

    const calculateShipping = () => {
        let cubic_price = $("#cubicPrice").val();
        let dollarRate = $("#dollarRate").val();
        let dutyCharges = $("#dutyCharges").val();
        let warfCharges = $("#warfCharges").val();
        let baggageCharges = $("#baggageCharges").val();
        let serviceCharges = $("#serviceCharges").val();
        let agencyCharges = $("#agencyCharges").val();
        let hidden = $("#hidden").val();

        width = $("#width").val();
        height = $("#height").val();
        length = $("#length").val();

        volumeBlock
            .find(".width")
            .children("h4")
            .text(width + " inches");
        volumeBlock
            .find(".height")
            .children("h4")
            .text(height + " inches");
        volumeBlock
            .find(".length")
            .children("h4")
            .text(length + " inches");

        let shipping_volume = ((length * width * height) / 1728).toFixed(2);
        let shipping_duty_volume = ((length * width * height) / 61024).toFixed(2);
        let shipping_amount = (shipping_volume * cubic_price).toFixed(2);
        let duty_amount = ((shipping_duty_volume * dutyCharges) / dollarRate).toFixed(2);
        let warf_charges = ((shipping_volume * warfCharges) / dollarRate).toFixed(2);
        let baggage_charges = (baggageCharges / dollarRate).toFixed(2);
        // let baggage_charges = ((shipping_volume * baggageCharges) / dollarRate).toFixed(2);
        let service_charges = ((shipping_volume * serviceCharges) / dollarRate).toFixed(2);
        let agency_charges = (agencyCharges / dollarRate).toFixed(2);

        let net_total =
            +shipping_amount +
            +duty_amount +
            +warf_charges +
            +baggage_charges +
            +service_charges +
            +agency_charges;

        priceBlock.find("h4").text(formatNumber(net_total.toFixed(2)));
        // priceBlock.find("h4").text(Number(net_total).toFixed(2));

        console.log({
            shipping_amount,
            warf_charges,
            baggage_charges,
            service_charges,
            agency_charges,
            duty_amount,
            net_total,
        });
    };

    if (body.hasClass("quote_calculator")) {
        $(document).on("change", "input[name=type]", function (e) {
            shipping_type = $(this).val();
            $(".radio-wrapper").removeClass("active");

            let parentWrapper = $(this).parents(".radio-wrapper");
            parentWrapper.addClass("active");

            let img = parentWrapper.find("img").attr("src");
            let text = parentWrapper.find(".spip-air-txt").text();

            shippingBlock.find("img").attr("src", img);
            shippingBlock.find(".ship-type-txt").text(text);
        });

        $(document).on("submit", "#calculateShipping", function (e) {
            e.preventDefault();

            calculateShipping();
        });

        calculateShipping();

    }

})(jQuery);
