require('./bootstrap');

require('./admin/plugins/datetime/gijgo.min');
require('./admin/plugins/loader/waitMe.min');
require('owl.carousel');
require('slick-carousel');
require('@fancyapps/fancybox');
window.AOS = require('aos');
require('select2');

require('./web/global');
require('./web/main');
require('./web/quote_calculator');

