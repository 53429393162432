jQuery(document).ready(function($) {

    AOS.init();

    let packages = $('.packages');
    let $package = $('.package-item');

    $(document).on('click', '#add_item', function (e) {
        e.preventDefault();

        let package_item = $('.package-item:last').clone();

        let id = parseInt(package_item.attr('data-id'));
        let new_id = id + 1;

        package_item.attr('data-id', new_id);
        package_item.html(package_item.html().replaceAll('['+id+']', '['+new_id+']'));
        packages.append(package_item);

        $('#remove_item').removeClass('d-none');

    });

    $(document).on('click', '#remove_item', function (e) {
        e.preventDefault();

        if(packages.find('.package-item').length > 1){
            $('.package-item:last').remove();
        }

        if(packages.find('.package-item').length === 1){
            $('#remove_item').addClass('d-none');
        }

    });

    $('.address_box').on('change','input[type=radio]', function (e) {
        $(this).parent().siblings('li').removeClass('selected');
        $(this).parent().addClass('selected');
    });

    $(document).on('change', 'select[name=country_id]', function (){
        let country = $(this).val();
        loadStates(country);
    });

    $(document).on('change', 'select[name=state_id]', function (){
        let state_id = $(this).val();
        loadCities(state_id);
    });

    let model = 1;
    let countryField =  $('select[name=country_id]');

    $(document).on('click', '#senderModel', function () {
        model = 1;
        countryField.val(model);
        countryField.find('option[value=1]').prop('disabled', false);
        countryField.find('option[value=1]').siblings('option').prop('disabled', true);
        loadStates(1);
        $('#addressModal').modal('show');
    });

    $(document).on('click', '#receiverModel', function () {
        model = 2;
        countryField.val(model);
        countryField.find('option[value=2]').prop('disabled', false);
        countryField.find('option[value=1]').prop('disabled', true);
        loadStates(2);
        $('#addressModal').modal('show');
    });

    $(document).on('submit', '#addressForm', function (e) {
        e.preventDefault();

        let form = $('#addressForm');
        let formElement = document.getElementById("addressForm");
        let data = new FormData(formElement);
        let endpoint = form.attr('action');
        form.find('button[type=submit]').attr('disabled', true);

        jQuery.ajax({
            type: 'post',
            url: endpoint,
            data: data,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (res) {

               form.find('button[type=submit]').attr('disabled', false);

                if(res.data.address){

                    let address = res.data.address;
                    let ul_element = model === 1 ? '#senderUl' : '#receiverUl';
                    let wrapper = $('.addres-list-wrap');

                    if($(ul_element).length){

                        let radio = model === 1 ? 'from' : 'to';
                        let li = '<li class="item selected">' +
                            '<h4>'+address.name+'</h4>' +
                            '<p>'+address.full_address+'</p>' +
                            '<input class="address_radio" type="radio" name="'+radio+'" value="'+address.id+'" checked>' +
                            '</li>';

                        $(ul_element).find('li').removeClass('selected');
                        $(ul_element).prepend(li);

                        let scrollTo = $(ul_element).find('li.selected');
                        let position = scrollTo.offset().top
                            - $(ul_element).offset().top
                            + $(ul_element).scrollTop();

                        $(ul_element).animate({
                            scrollTop: position
                        }, 'slow');
                    }

                    formElement.reset();
                    $('#addressModal').modal('hide');

                    if(wrapper.length){
                       wrapper.prepend(res.data.html);
                    }

                }
            },
            error: function (error) {
                form.find('button[type=submit]').attr('disabled', false);
                let response = jQuery.parseJSON(error.responseText);
                console.error(response);

                if(response.errors){

                    $('input').removeClass('is-invalid');
                    $.each(response.errors, function (index, val){
                        let input = $('input[name="'+index+'"]');
                        input.addClass('is-invalid');
                        input.parent().find('.invalid-feedback').remove();
                        let errorMessage = '<span class="invalid-feedback" role="alert"><strong>'+val+'</strong></span>';
                        input.parent().append(errorMessage);
                    });

                }
            }
        });

    })

    $('#trackPackageModal').on('show.bs.modal', function () {
        $('.shipment_form').removeClass('d-none');
        $('.shipment_result').remove();
        $('#trackPackage')[0].reset();
        $('#trackPackage').find('button[type=submit]').attr('disabled', false);
    })

    $(document).on('submit', '#trackPackage', function (e) {
        e.preventDefault();

        let form = $('#trackPackage');
        let formElement = document.getElementById("trackPackage");
        let data = new FormData(formElement);
        let endpoint = form.attr('action');
        form.find('button[type=submit]').attr('disabled', true);

        jQuery.ajax({
            type: 'post',
            url: endpoint,
            data: data,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (res) {

                form.find('button[type=submit]').attr('disabled', false);

                if(res.html){

                    formElement.reset();
                    $('.shipment_form').addClass('d-none');
                    $('#trackPackageModal').find('.modal-body').append(res.html);

                }
            },
            error: function (error) {

                $('.shipment_form').removeClass('d-none');
                form.find('button[type=submit]').attr('disabled', false);
                formElement.reset();

                let response = jQuery.parseJSON(error.responseText);
                console.error(response);

            }
        });
    });

    $(document).on('submit', '#subscriptionForm', function (e) {
        e.preventDefault();

        let form = $('#subscriptionForm');
        let formElement = document.getElementById("subscriptionForm");
        let data = new FormData(formElement);
        let endpoint = form.attr('action');
        form.find('button[type=submit]').attr('disabled', true);

        jQuery.ajax({
            type: 'post',
            url: endpoint,
            data: data,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (res) {

                form.find('button[type=submit]').attr('disabled', false);
                let message = '<div class="alert alert-danger">' + res.message + '</div>';

                if(res.status){
                    message = '<div class="alert alert-success">' + res.message + '</div>';
                }

                form.addClass('d-none');
                $('.newsletter-wrap').append(message);

            },
            error: function (error) {

                form.find('button[type=submit]').attr('disabled', false);
                formElement.reset();

                let response = jQuery.parseJSON(error.responseText);
                console.error(response);

            }
        });
    });

    $(document).on('submit', '#authForm', function (e) {

        $('#authBlock').waitMe({
            effect  : 'bounce',
            text    : '',
            bg      : 'rgba(255,255,255,0.7)',
            color   : '#000'
        });

    });



    function loadStates(country){

        let endpoint = home_url + '/api/country/' + country + '/states';

        $.fetch(endpoint, [], 'json', function (res) {

            let items = '<option value="" >---</option>';

            $.each(res.data.states, function (index, state) {
                items += '<option value="'+ state.id +'">' + state.state + '</option>';
            })

            $('select[name="state_id"]').html('').append(items);

        }, function (error) {
            console.log(error);
        });
    }

    function loadCities(state_id){

        let endpoint = home_url + '/api/state/' + state_id + '/cities';

        $.fetch(endpoint, [], 'json', function (res) {

            let items = '<option value="" >---</option>';

            $.each(res.data.states, function (index, state) {
                items += '<option value="'+ state.id +'">' + state.city + '</option>';
            })

            $('select[name="city_id"]').html('').append(items);

        }, function (error) {
            console.log(error);
        });
    }

    $('.testi-slider-items').owlCarousel({
          items:1,
          loop:true,
          nav: false,
          dots: true,
          animateOut: 'fadeOut',
          autoplay:false,
          autoplayTimeout:3000,
          autoplayHoverPause: true
      });

    $('.main-menu-wrap ul li:has(ul)').addClass('has-child');
    $('.main-menu-wrap ul li:has(ul)').prepend('<span class="nav-click" />');
    $('#menu-toggle').click(function() {
        $('.menu > ul').toggleClass('open-menu');
        $('body').toggleClass('fix');
        $('.menu').toggleClass('open');
        // $('.mobile-login').toggleClass('hide');
    });

    $('.nav-click').click(function() {
        $(this).parent().find('ul').toggleClass('subshow');
        $(this).toggleClass('arrowup');
    });
    $('#menu-toggle').click(function() {
        $(this).toggleClass('open');
    });

    $('.mob-menu ul li:has(ul)').addClass('has-child');
    $('.mob-menu ul li:has(ul)').prepend('<span class="nav-click" />');
    $('#menu-toggle').click(function() {
        $('.menu > ul').toggleClass('open-menu');
        $('body').toggleClass('fix');
        $('.menu').toggleClass('open');
        // $('.mobile-login').toggleClass('hide');
    });
    // $('.nav-click').click(function() {
    //     $('.menu ul li ul').toggleClass('subshow');
    //     $('.nav-click').toggleClass('arrowup');
    // });
    $('.nav-click').click(function() {
        $(this).parent().find('ul').toggleClass('subshow');
        $(this).toggleClass('arrowup');
    });
    $('#menu-toggle').click(function() {
        $(this).toggleClass('open');
    });
    /* $(document).click(function () {
      if($(".current-scdule-pop-up-wrap").hasClass("popup-open")){
        $(".current-scdule-pop-up-wrap").removeClass("popup-open");
      }
  }); */

    $(".ham-icon .fas").click(function(){
          $(".mob-menu-wrap").addClass("open-menu");
      });
      $(".close-icon .fas").click(function(){
          $(".mob-menu-wrap").removeClass("open-menu");
      });

      $('.faq-item .faq-head').click(function(){
        if($(this).parent().hasClass('active')){
          $(this).parent().removeClass('active');
        }else{
          $('.faq-item').removeClass('active');
          $(this).parent().addClass('active');
        }
      });

    loadStates(1);

  });

jQuery(window).scroll(function(){
  if (jQuery(this).scrollTop() > 50) {
      jQuery('#header').addClass('scroll-menu');
  } else {
      jQuery('#header').removeClass('scroll-menu');
  }
  if ($(this).scrollTop() > 150) {
      $('#scroll-icon-w').addClass("scrolled-nav");
  }else {
      $('#scroll-icon-w').removeClass("scrolled-nav", 1000, "easeInOut" );
  }
});


jQuery('#scroll-icon-w').click(function(){
    jQuery("html, body").animate({ scrollTop: 0 }, 600);
    return false;
});

// $(".current-schedule-in-wrap a").click(function(){
//     $(".current-scdule-pop-up-wrap").toggleClass("popup-open");
// });

$(document).on('click', '.current-schedule-in-wrap a', function() {
    $(".current-scdule-pop-up-wrap").toggleClass("popup-open");
});
